import React from 'react'
import { Layout, Col, Row, Image, Typography, Button, Space, Form, Input, InputNumber} from 'antd';
import { PlayCircleOutlined } from '@ant-design/icons';
import Seo from "../Seo"

import SignUpImg from "../../images/signup.png"
import './sign-up.less'
const { Content } = Layout;
const { Title, Paragraph, Text, Link } = Typography;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

/* eslint-disable no-template-curly-in-string */
const validateMessages = {
  required: '${label} is required!',
  types: {
    email: '${label} is not a valid email!',
    number: '${label} is not a valid number!',
  },
  number: {
    range: '${label} must be between ${min} and ${max}',
  },
};
/* eslint-enable no-template-curly-in-string */


export default function ContactUs({title, description}) {

  let location = null
  if (typeof window !== `undefined`){
    location = window.location
  }

  const onFinish = (values) => {
    console.log(values);
  };

  return (
    <Content className='contactus-root'>
      <Seo
        title={title}
        description={description}
        // image={`http:${post.heroImage.resize.src}`}
        type="Organization"
        origin={location != null ? location.origin : null}
        pathname={location != null ? location.pathname : null}
      />
      <Col className='contactus-container' >
        <Row style={{alignItems: 'center'}}>
          <Typography>
            <Title className="contactus-title">Create an account</Title>
            <Paragraph className='contactus-subtitle'>
              Start your 14 days free trial. No credit card required.
            </Paragraph>
          </Typography>
        </Row>
        <Row style={{paddingTop: 24, justifyContent: "flex-start"}}>
          <Col className="image" >
          <Image
              width={450}
              src={SignUpImg}
              preview={false}
            />
          </Col>
          <Col className="form" >
            <div className="form-header">Sign up form</div>
            <Form {...layout} name="nest-messages" onFinish={onFinish} validateMessages={validateMessages}>
              <Form.Item name={['user', 'first-name']} label="First name" rules={[{ required: true }]}>
                <Input size="large"/>
              </Form.Item>
              <Form.Item name={['user', 'last-name']} label="Last name" rules={[{ required: true }]}>
                <Input size="large"/>
              </Form.Item>
              <Form.Item name={['user', 'company-name']} label="Company name" rules={[{ required: true }]}>
                <Input size="large"/>
              </Form.Item>
              <Form.Item name={['user', 'mobile-number']} label="Mobile number" rules={[{ required: true }]}>
                <Input size="large"/>
              </Form.Item>
              <Form.Item name={['user', 'email']} label="Email" rules={[{ type: 'email', required: true }]}>
                <Input size="large"/>
              </Form.Item>
              <Form.Item name={['user', 'password']} label="Password" rules={[{ required: true }]}>
                <Input size="large"/>
              </Form.Item>
              <Form.Item name={['user', 'confirm-password']} label="Confirm password" rules={[{ required: true }]}>
                <Input size="large"/>
              </Form.Item>
              <Form.Item  style={{float: "right"}}>
                <Button type="primary" htmlType="submit" size="large" style={{marginTop: 16}}>
                  Create account
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Col>
    </Content>
  )
}