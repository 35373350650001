import React from 'react'
import Layout from "../components/Layout"
import SignUp from "../components/SignUp"
import {Col, Divider} from 'antd'

export default function index() {
  const title="FieldNext - Sign up"
  const description="Start your 14 days free trial. No credit card required."

  return <Layout>
    <SignUp
      title={title}
      description={description}
    />
    <Divider/>
  </Layout>
}
